<template>
  <div class="header">
    <div class="logo">
      <img class="logoimg" :src="logoUrl" alt="logo">
      <!-- <img class="logoimg" src="http://18.141.56.95:18001/3mgoo/logo/1723519227.png" alt="logo"> -->
      <span class="title">3mgoo游戏平台</span>
    </div>
    <ul class="menu">
      <router-link to="/" ><li @click="changeRouter()" :class="{active:hrefStr=='/'}"><span>{{ $t("menu.home") }}</span></li></router-link>
      <router-link to="/games" ><li @click="changeRouter()" :class="{active:hrefStr=='/games'}"><span>{{ $t("menu.game") }}</span></li></router-link>
      <router-link to="/news" ><li @click="changeRouter()" :class="{active:hrefStr=='/news'}"><span>{{ $t("menu.news") }}</span></li></router-link>
      <router-link to="/aboutus"><li @click="changeRouter()" :class="{active:hrefStr=='/aboutus'}"><span>{{ $t("menu.us") }}</span></li></router-link>
      <router-link to="/contact"><li @click="changeRouter()" :class="{active:hrefStr=='/contact'}"><span>{{ $t("menu.contact") }}</span></li></router-link>
      <li class="language_btn" @click="chagelanguage">EN/中文</li>
    </ul>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'Header',
  data(){
    return {
      hrefStr:'/',
      logoUrl:'',
      lang:{
        lang:1
      }
    }
  },
  created(){
    this.getLogo()
  },
  
  watch:{
    '$i18n.locale':function (val, oldVal) {
      this.lang.lang = val=='zh'?'1':'2'
      this.getLogo()
    },
    '$route':function (val, oldVal) {
      this.hrefStr = val.path
    }
  },

  methods:{
    getLogo(){
      let params = {
        ...this.lang
      }
      axios.get(process.env.VUE_APP_BASE_API+'/logo/list',{params}).then(res=>{
        if(res.data.data.length>0){
          this.logoUrl = res.data.data[0].url
        }else{
          this.logoUrl = ''
        }
        
      }).catch(err=>{
        // console.log(err)
      });
    },
    changeRouter(){
      // setTimeout(()=>{
      //   this.hrefStr = this.$route.path
      // },100)
    },
    chagelanguage(){
      this.$i18n.locale == 'zh' ? this.$i18n.locale = 'en' : this.$i18n.locale = 'zh'
      
    }
  }
}
</script>

<style scoped>
.active span{
  color: rgba(204, 204, 204, 1)!important;
}
.header{
  display: flex;
  width: 1200px;
  margin: 0 auto;
  justify-content: space-between;
}
.logo{
  display: flex;
  align-items: center;
}
.logo .logoimg{
  height: 101px;
}
.logo .title{
  color: rgb(255, 255, 255);
  font-size: 24px;
}
.menu{
  width: 500px;
  display: flex;
}
.menu li span,.language_btn{
  color: rgba(128, 128, 128, 1);
  font-size: 16px;
  height: 121px;
  line-height: 121px;
  min-width: 60px;
  text-align: center;
  padding: 0 10px;
  display: block;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}
/* .language_btn{
  cursor: pointer;
} */

</style>