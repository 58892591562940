import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    meta:{requireAuth:false},
    component: () => import('../views/Index.vue'),
  },
  {
    path: '/tree',
    name: 'tree',
    meta:{requireAuth:false},
    component: () => import('../views/tree.vue')
  },
  {
    path: '/news',
    name: 'news',
    meta:{requireAuth:false},
    component: () => import('../views/news.vue')
  },
  {
    path: '/aboutus',
    name: 'aboutus',
    meta:{requireAuth:false},
    component: () => import('../views/aboutUs.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    meta:{requireAuth:false},
    component: () => import('../views/contact.vue')
  },
  {
    path: '/games',
    name: 'games',
    meta:{requireAuth:false},
    component: () => import('../views/games.vue')
  },
  {
    path: '/newsContent',
    name: 'newsContent',
    meta:{requireAuth:false},
    component: () => import('../views/newsContent.vue')
  },
]
const router = new VueRouter({
  mode: 'history',
  // mode: 'hash',
  base: '/',
  // base: '/dist',
  routes
})
// console.log(process.env.BASE_URL);


export default router
